<template>
<div class="min-h-full pt-16 pb-12 flex flex-col bg-white">
  <main class="flex-grow flex flex-col justify-center max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
    <div class="flex-shrink-0 flex justify-center">
      <a href="/" class="inline-flex">
        <span class="sr-only">Complaia</span>
        <img class="h-12 w-auto" src="https://tailwindui.com/img/logos/workflow-mark.svg?color=purple&shade=600" alt="">
      </a>
    </div>
    <div class="py-16">
      <div class="text-center">
        <p class="text-sm font-semibold text-purple-600 uppercase tracking-wide">Connection error</p>
        <h1 class="mt-2 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">No Internet connection.</h1>
        <p class="mt-2 text-base text-gray-500">Your computer probably doesn't have internet access. The app should unlock itself after restoring it.</p>
        <div class="mt-6">
          <div @click.prevent="reload()" class="text-base font-medium text-blue-600 hover:text-blue-500 underline cursor-pointer">Click here to reload app</div>
        </div>
      </div>
    </div>
  </main>
</div>

</template>

<script>
    //import axios from 'axios';
    export default {
        name: "InternetConnection",
        methods:
        {
            reload()
            {
                window.location.reload();
            }
        },
        created()
        {
            
        }
    }
</script>