<template>
  <div>
    <TransitionRoot as="template" :show="sidebarOpen">
      <Dialog as="div" class="fixed inset-0 flex z-40 md:hidden" @close="sidebarOpen = false">
        <TransitionChild as="template" enter="transition-opacity ease-linear duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="transition-opacity ease-linear duration-300" leave-from="opacity-100" leave-to="opacity-0">
          <DialogOverlay class="fixed inset-0 bg-gray-600 bg-opacity-75" />
        </TransitionChild>
        <TransitionChild as="template" enter="transition ease-in-out duration-300 transform" enter-from="-translate-x-full" enter-to="translate-x-0" leave="transition ease-in-out duration-300 transform" leave-from="translate-x-0" leave-to="-translate-x-full">
          <div class="relative flex-1 flex flex-col max-w-xs w-full bg-white">
            <TransitionChild as="template" enter="ease-in-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in-out duration-300" leave-from="opacity-100" leave-to="opacity-0">
              <div class="absolute top-0 right-0 -mr-12 pt-2">
                <button type="button" class="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white" @click="sidebarOpen = false">
                  <span class="sr-only">Close sidebar</span>
                  <ArrowLeftIcon class="h-6 w-6 text-white" aria-hidden="true" />
                </button>
              </div>
            </TransitionChild>
            <div class="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
              <div class="flex-shrink-0 flex items-center px-4">
                <img class="h-8 w-auto" src="https://firebasestorage.googleapis.com/v0/b/complaia.appspot.com/o/webAssets%2Flogo%2Bnapis.svg?alt=media&token=3ecb12fa-7975-47cd-a69e-79a96f199b10" alt="Workflow" />
              </div>
              <!-- <nav class="mt-5 px-2 space-y-1">
                <router-link @click="sidebarOpen = false" v-for="item in navigation" :key="item.name" :to="item.permission ? item.href : ''" :class="[$route.path === item.href ? 'bg-gray-100 text-gray-900' : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900', 'group flex items-center px-2 py-2 text-sm font-medium rounded-md']">
                  <component :is="item.icon" :class="[$route.path === item.href ? 'text-purple-500' : 'text-purple-300 group-hover:text-purple-400', 'mr-3 flex-shrink-0 h-6 w-6']" aria-hidden="true" />
                  {{ item.name }}
                </router-link>
              </nav> -->

              <nav class="mt-5 flex-1 px-2 space-y-1">
                <div v-for="item in navigation" :key="item.name" @click.prevent="closeSideBar(item.permission), changeLocation(item.permission ? item.href : '', item.permission)" :class="[$route.path === item.href ? 'bg-gray-100 text-gray-900 border-2 border-purple-100' : 'text-gray-600 hover:bg-gray-100 hover:text-gray-900', 'transition ease-in-out duration-200 group flex items-center px-2 py-2 text-sm font-medium rounded-md', item.permission ? 'cursor-pointer' : 'cursor-not-allowed', (item.name === 'Permissions' && item.permission ? '' : '')]">
                  <component :is="item.icon" :class="[item.current ? 'text-purple-400' : 'text-purple-400 group-hover:text-purple-300', item.permission ? '' : 'opacity-40', 'transition ease-in-out duration-200 mr-3 flex-shrink-0 h-6 w-6 ']" aria-hidden="true" />
                  <span :class="[item.permission ? '' : 'opacity-40']">{{ item.name }} </span>
                  <div @click="goToAccount()" v-if="!item.permission && this.$store.state.userData.systemCreator" :class="['transition ease-in-out duration-300 ml-auto p-1px bg-gradient-to-r from-purple-500 to-cyan-400 rounded-lg opacity-100', this.$store.state.userData.systemCreator === true ? 'cursor-pointer hover:opacity-80' : 'pointer-events-none']">
                    <div class="bg-purple-50 rounded-lg px-1.5 flex justify-center items-center">
                      <span class="opacity-100 text-xxs-1 font-semibold text-transparent bg-clip-text bg-gradient-to-r from-purple-600 to-cyan-500">PRO</span>
                    </div>
                  </div>
                  <div v-if="!item.permission && !this.$store.state.userData.systemCreator" :class="['transition ease-in-out duration-300 ml-auto p-1px bg-gradient-to-r from-gray-500 to-gray-300 rounded-lg opacity-100 pointer-events-none']">
                <div class="bg-gray-50 rounded-lg px-1.5 flex justify-center items-center">
                  <span class="opacity-100 text-xxs-1 font-semibold text-transparent bg-clip-text bg-gradient-to-r from-gray-600 to-gray-400">BLOCKED</span>
                </div>
              </div>
                </div>
                <div @click="logout" class="transition ease-in-out duration-200 select-none cursor-pointer text-gray-600 hover:bg-gray-100 hover:text-gray-900 group flex items-center px-2 py-2 text-sm font-medium rounded-md"><LogoutIcon class="transition ease-in-out duration-200 text-purple-400 group-hover:text-purple-300 mr-3 flex-shrink-0 h-6 w-6" />
                  Logout</div>
              </nav>
            </div>
            <div class="flex-shrink-0 w-full group flex justify-center px-2 py-1">
              <router-link to="/dashboard/support" class="transition ease-in-out duration-200 text-xxs mb-1 text-purple-500 opacity-50 hover:opacity-100">
                - Complaia support -
              </router-link>
            </div>
            <router-link to="/dashboard/profile" class="flex-shrink-0 w-full group block ">
                <div @click="sidebarOpen = false" :class="{'transition ease-in-out duration-200 flex-shrink-0 flex border-t border-gray-200 hover:border-gray-300 p-4 hover:bg-gray-100': true, 'bg-gray-100': $route.path === '/dashboard/profile'}">
                    <div class="flex w-full justify-between">
                      <div class="flex items-between">
                        <div>
                            <img class="inline-block h-9 w-9 rounded-full" :src="$store.state.user.photoURL" alt="Manage profile and system settings on Complaia" />
                        </div>
                        <div class="ml-3">
                            <p class="text-sm font-medium text-gray-700 group-hover:text-gray-900">{{$store.state.user.displayName.substring(0,15)}}{{$store.state.user.displayName.length > 15 ? '...' : ''}}</p>
                            <p class="text-xs font-medium text-gray-500 group-hover:text-gray-700">Profile and system</p>
                        </div>
                      </div>
                        <div class="flex items-center">
                            <ChevronRightIcon class="h-4 w-4 text-gray-400" aria-hidden="true" />
                        </div>
                    </div>
                </div>
            </router-link>
          </div>
        </TransitionChild>
        <div class="flex-shrink-0 w-14">
          <!-- Force sidebar to shrink to fit close icon -->
        </div>
      </Dialog>
    </TransitionRoot>

    <!-- Static sidebar for desktop -->
    <div class="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0 select-none">
      <!-- Sidebar component, swap this element with another sidebar if you like -->
      <div class="flex-1 flex flex-col min-h-0 border-r border-gray-200 bg-gray-50">
        <div class="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
          <div class="flex items-center flex-shrink-0 px-4">
            <img class="h-8 w-auto" src="https://firebasestorage.googleapis.com/v0/b/complaia.appspot.com/o/webAssets%2Flogo%2Bnapis.svg?alt=media&token=3ecb12fa-7975-47cd-a69e-79a96f199b10" alt="Complaia app" />
          </div>
          <nav class="mt-5 flex-1 px-2 bg-gray-50 space-y-1">
            <div v-for="item in navigation" :key="item.name" @click.prevent="changeLocation(item.permission ? item.href : '', item.permission)" :class="[$route.path === item.href ? 'bg-gray-100 text-gray-900 border-2 border-purple-100' : 'text-gray-600 hover:bg-gray-100 hover:text-gray-900', 'transition ease-in-out duration-200 group flex items-center px-2 py-2 text-sm font-medium rounded-md', item.permission ? 'cursor-pointer' : 'cursor-not-allowed', (item.name === 'Permissions' && item.permission ? '' : '')]">
              <component  :is="item.icon" :class="[item.current ? 'text-purple-400' : 'text-purple-400 group-hover:text-purple-300', item.permission ? '' : 'opacity-40', 'transition ease-in-out duration-200 mr-3 flex-shrink-0 h-6 w-6 ']" aria-hidden="true" />
              <span :class="[item.permission ? '' : 'opacity-40']">{{ item.name }} </span>
              <div @click="goToAccount()" v-if="!item.permission && this.$store.state.userData.systemCreator" :class="['transition ease-in-out duration-300 ml-auto p-1px bg-gradient-to-r from-purple-500 to-cyan-400 rounded-lg opacity-100', this.$store.state.userData.systemCreator === true ? 'cursor-pointer hover:opacity-80' : 'pointer-events-none']">
                <div class="bg-purple-50 rounded-lg px-1.5 flex justify-center items-center">
                  <span class="opacity-100 text-xxs-1 font-semibold text-transparent bg-clip-text bg-gradient-to-r from-purple-600 to-cyan-500">PRO</span>
                </div>
              </div>
              <div v-if="!item.permission && !this.$store.state.userData.systemCreator" :class="['transition ease-in-out duration-300 ml-auto p-1px bg-gradient-to-r from-gray-500 to-gray-300 rounded-lg opacity-100 pointer-events-none']">
                <div class="bg-gray-50 rounded-lg px-1.5 flex justify-center items-center">
                  <span class="opacity-100 text-xxs-1 font-semibold text-transparent bg-clip-text bg-gradient-to-r from-gray-600 to-gray-400">BLOCKED</span>
                </div>
              </div>
              <!-- <span v-if="item.name === 'Chats'" class="flex ml-auto flex-shrink-0 items-center justify-center">
                <span class="h-1.5 w-1.5 bg-purple-500 rounded-full" aria-hidden="true" />
              </span> -->
            </div>
            <div @click="logout" class="transition ease-in-out duration-200 select-none cursor-pointer text-gray-600 hover:bg-gray-100 hover:text-gray-900 group flex items-center px-2 py-2 text-sm font-medium rounded-md"><LogoutIcon class="transition ease-in-out duration-200 text-purple-400 group-hover:text-purple-300 mr-3 flex-shrink-0 h-6 w-6" />
               Logout</div>
          </nav>
        </div>
        <div class="flex-shrink-0 w-full group flex justify-center px-2 py-1">
          <router-link to="/dashboard/support" class="transition ease-in-out duration-200 text-xxs mb-1 text-purple-500 opacity-50 hover:opacity-100">
            - Complaia support -
          </router-link>
        </div>
        <router-link to="/dashboard/profile" class="flex-shrink-0 w-full group block ">
            <div :class="{'transition ease-in-out duration-200 flex-shrink-0 flex border-t border-gray-200 hover:border-gray-300 p-4 hover:bg-gray-100': true, 'bg-gray-100': $route.path === '/dashboard/profile'}">
                <div class="flex w-full justify-between">
                      <div class="flex items-between">
                        <div>
                            <img class="inline-block h-9 w-9 rounded-full" :src="$store.state.user.photoURL" alt="Manage profile and system settings on Complaia" />
                        </div>
                        <div class="ml-3">
                            <p class="text-sm font-medium text-gray-700 group-hover:text-gray-900">{{$store.state.user.displayName.substring(0,15)}}{{$store.state.user.displayName.length > 15 ? '...' : ''}}</p>
                            <p class="text-xs font-medium text-gray-500 group-hover:text-gray-700">Profile and system</p>
                        </div>
                      </div>
                        <div class="flex items-center">
                            <ChevronRightIcon class="h-4 w-4 text-gray-400" aria-hidden="true" />
                        </div>
                    </div>
            </div>
        </router-link>
      </div>
    </div>
    <div class="md:pl-64 flex flex-col flex-1">
      <div class="sticky top-0 z-10 md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3 bg-white ">
        <button type="button" class="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-purple-500" @click="sidebarOpen = true">
          <span class="sr-only">Open sidebar</span>
          <MenuAlt1Icon class="h-6 w-6" aria-hidden="true" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
    import { Dialog, DialogOverlay, TransitionChild, TransitionRoot } from '@headlessui/vue'
    import {
    ViewGridAddIcon,
    ChevronRightIcon,
    UsersIcon,
    MenuAlt1Icon,
    LogoutIcon,
    ArrowLeftIcon,
    TemplateIcon,
    CubeIcon,
    UserGroupIcon,
    LinkIcon,
    PresentationChartLineIcon,
    ChatAlt2Icon,
    LockOpenIcon
    } from '@heroicons/vue/outline'

export default {
  components: {
    Dialog,
    DialogOverlay,
    TransitionChild,
    TransitionRoot,
    MenuAlt1Icon,
    ArrowLeftIcon,
    ViewGridAddIcon,
    LogoutIcon,
    CubeIcon,
    ChevronRightIcon,
    TemplateIcon,
    UserGroupIcon,
  },
  data() {
    return {
      sidebarOpen: false,
      analyticsRule: null,
      navigation: [
        { name: 'Feed', href: '/dashboard/feed', icon: TemplateIcon , current: false, permission: true },
        { name: 'Analytics', href: '/dashboard/analytics', icon: PresentationChartLineIcon , current: false, permission: this.$store.state.userData.permissions.analytics },
        { name: 'Complaints', href: '/dashboard/complaints', icon: ViewGridAddIcon, current: false, permission: true },
        { name: 'Products', href: '/dashboard/products', icon: CubeIcon, current: false, permission: true },
        { name: 'Chats', href: '/dashboard/chats', icon: ChatAlt2Icon, current: false, permission: true },
        { name: 'Clients', href: '/dashboard/clients', icon: UserGroupIcon, current: false, permission: this.$store.state.userData.systemCreator === true || this.$store.state.userData.permissions.editingCustomers === true },
        { name: 'Customer Portal', href: '/dashboard/customerportal', icon: LinkIcon, current: false, permission: this.$store.state.userData.permissions.customerPortal },
        { name: 'Team', href: '/dashboard/team', icon: UsersIcon, current: false, permission: true },
        { name: 'Permissions', href: '/dashboard/permissions', icon: LockOpenIcon, current: false, permission: this.$store.state.userData.permissions.editingPermissions },
      ],
    }
  },
  created() {
  },
  mounted(){
  },
  methods: {
    changeLocation(location, permission) {
      if(permission && this.$route.path !== location) {
        if(location === '/dashboard/permissions') {
          this.$store.commit('setGlobalLoader',{
            show: true,
            head: "Loading user permissions..",
            subheader: `Do not close this tab.`,
          });
          setTimeout(() => {
            this.$router.push(location)
          },350) 
        } else if(location === '/dashboard/team') {
          this.$store.commit('setGlobalLoader',{
            show: true,
            head: "Loading your team..",
            subheader: `Do not close this tab.`,
          });
          setTimeout(() => {
            this.$router.push(location)
          },350) 
        } else if(location === '/dashboard/feed') {
          this.$store.commit('setGlobalLoader',{
            show: true,
            head: "Loading your statistics..",
            subheader: `Do not close this tab.`,
           });
          setTimeout(() => {
            this.$router.push(location)
          },350) 
        } else if(location === '/dashboard/analytics') {
          this.$store.commit('setGlobalLoader',{
            show: true,
            head: "Loading your statistics..",
            subheader: `Do not close this tab.`,
           });
          setTimeout(() => {
            this.$router.push(location)
          },350) 
        } else if(location === '/dashboard/customerportal') {
          this.$store.commit('setGlobalLoader',{
            show: true,
            head: "Loading your customer portal..",
            subheader: `Do not close this tab.`,
           });
          setTimeout(() => {
            this.$router.push(location)
          },350) 
        } else {
          this.$router.push(location)
        }

      }
    },
    closeSideBar(permision){
      if(permision){
        this.sidebarOpen = false
      }
    },
    goToAccount(){
      if(this.$store.state.userData.systemCreator === true){
        // this.$router.push('/dashboard/profile?billing=true', '_blank')
        window.open('/dashboard/profile?billing=true', '_blank');
      }
    },
    async logout() {
      try {
        await this.$store.dispatch("logout");
        this.$router.push("/");
      } catch (err) {
        console.log(err.message);
      }
  }
}
}

    // const navigation = [
    // { name: 'Feed', href: '/dashboard/feed', icon: TemplateIcon , current: false, permission:  },
    // { name: 'Analytics', href: '/dashboard/analytics', icon: PresentationChartLineIcon , current: false },
    // { name: 'Complaints', href: '/dashboard/complaints', icon: ViewGridAddIcon, current: false },
    // { name: 'Products', href: '/dashboard/products', icon: CubeIcon, current: false },
    // { name: 'Clients', href: '/dashboard/clients', icon: UserGroupIcon, current: false },
    // { name: 'Customer Portal', href: '/dashboard/customerportal', icon: LinkIcon, current: false },
    // { name: 'Team', href: '/dashboard/team', icon: UsersIcon, current: false },
    // { name: 'Permissions', href: '/dashboard/permissions', icon: LockOpenIcon, current: false, permission: store.state.userData.systemCreator === true  },
    // ];

    /*
    async logout() {
      try {
        await this.$store.dispatch("logout");
        this.$router.push("/");
      } catch (err) {
        console.log(err.message);
      }
    };
    */

</script>