<template>
  <div class="dashboard">
    <Transition name="fade">
      <Loader v-if="!$store.state.authIsReady || $store.state.globalLoader.show" :header="$store.state.globalLoader.head"  :subheader="$store.state.globalLoader.subheader"></Loader>
    </Transition>
    <Nav v-if="$store.state.authIsReady && $store.state.internetConnection"></Nav>
    <transition name="fade">
      <Notification></Notification>
    </transition>
    <div class="md:pl-64 flex flex-col flex-1" v-if="$store.state.authIsReady && $store.state.internetConnection">
      <main class="flex-1">
        <div class="py-6">
          <div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
            <h1 class="text-2xl font-semibold text-gray-900">{{$route.meta.pageTitle}}</h1>
          </div>
          <div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
            <router-view></router-view>
          </div>
        </div>
      </main>
    </div>
    <AlertModal v-if="showAlert" :buttonColor="'purple'" :iconColor="'yellow'" :showCancelButton="false" :modalData="alertData" @accept="hideAlert()"></AlertModal>
    <InternetConnectionLost v-show="!$store.state.internetConnection"></InternetConnectionLost>

    
    
    <!-- <div v-if="$store.state.authIsReady">{{ $store.state.user.email }}</div>
    <button v-if="$store.state.authIsReady" @click="logout()">Logout</button> -->

  </div>
</template>
<script>


import { auth,db } from "@/firebase/gfbconf";
import Loader from '../components/Loader.vue';
import axios from 'axios';
import Nav from '../components/Nav.vue';
import Notification from '../components/Notification.vue';
import InternetConnectionLost from '../components/InternetConnectionLost.vue';
import AlertModal from '../components/AlertModal.vue'
// import {Checknet} from '@/checknet.min.js'

export default {
  components: { Loader, Nav, Notification, InternetConnectionLost, AlertModal },
  name: "Dashboard",

  data() {
    return {
      showAlert: false,
      alertData: {
          headerText: "Your plan has changed",
          bodyText: "",
          acceptButtonText: "I understand"
      },
    }
  },
  async created() {
    
    const getPermissions = () => {
      this.metadata = this.$store.state.userSubscription.items[0].price.product.metadata
      
      // Advanced feed permissions

      if(eval(this.metadata.advancedFeed) === false && this.$store.state.userData.systemCreator === true) {
        this.$store.state.userData.permissions.advancedFeed = false;
      } else if(eval(this.metadata.advancedFeed) === true && this.$store.state.userData.systemCreator === true) {
        this.$store.state.userData.permissions.advancedFeed = true;
      } else if(eval(this.metadata.advancedFeed) === false && this.$store.state.userData.systemCreator === false) {
        this.$store.state.userData.permissions.advancedFeed = false;
      } else if(eval(this.metadata.advancedFeed) === true && this.$store.state.userData.systemCreator === false) {
        this.$store.state.userData.permissions.advancedFeed = true;
      }
      // Analytics permissions

      if(eval(this.metadata.analyticsAccess) === false && this.$store.state.userData.systemCreator === true) {
        this.$store.state.userData.permissions.analytics = false;
      } else if(eval(this.metadata.analyticsAccess) === true && this.$store.state.userData.systemCreator === true) {
        this.$store.state.userData.permissions.analytics = true;
      }
      // Customer Portal styling permissions

      if(eval(this.metadata.customerPortalStyling) === false && this.$store.state.userData.systemCreator === true) {
        this.$store.state.userData.permissions.customerPortalStyling = false;
      } else if(eval(this.metadata.customerPortalStyling) === true && this.$store.state.userData.systemCreator === true) {
        this.$store.state.userData.permissions.customerPortalStyling = true;
      } else if(eval(this.metadata.customerPortalStyling) === true && this.$store.state.userData.systemCreator === false) {
        this.$store.state.userData.permissions.customerPortalStyling = true;
      } else if(eval(this.metadata.customerPortalStyling) === false && this.$store.state.userData.systemCreator === false) {
        this.$store.state.userData.permissions.customerPortalStyling = false;
      }
      // Customizable Roles/Editing Permissions 

      if(eval(this.metadata.customizableRoles) === false && this.$store.state.userData.systemCreator === true) {
        this.$store.state.userData.permissions.editingPermissions = false;
      } else if(eval(this.metadata.customizableRoles) === true && this.$store.state.userData.systemCreator === true) {
        this.$store.state.userData.permissions.editingPermissions = true;
      }
    }

    const relocateTo = () => {

        if(this.$route.path === '/dashboard/analytics' && this.$store.state.userData.permissions.analytics === false) {
          this.$router.push(this.$store.state.url)
        }
        if(this.$route.path === '/dashboard/product/edit' && this.$store.state.userData.permissions.editingProducts === false) {
          this.$router.push(this.$store.state.url)
        } 
        if(this.$route.path === '/dashboard/product/list' && this.$store.state.userData.permissions.editingProducts === false) {
          this.$router.push(this.$store.state.url)
        } 
        if(this.$route.path === '/dashboard/product/add' && this.$store.state.userData.permissions.editingProducts === false) {
          this.$router.push(this.$store.state.url)
        } 
        if(this.$route.path === '/dashboard/product/attributes' && this.$store.state.userData.permissions.editingProducts === false) {
          this.$router.push(this.$store.state.url)
        }  
        if(this.$route.path === '/dashboard/product/categories' && this.$store.state.userData.permissions.editingProducts === false) {
          this.$router.push(this.$store.state.url)
        } 
        if(this.$route.path === '/dashboard/product/manufacturers' && this.$store.state.userData.permissions.editingProducts === false) {
          this.$router.push(this.$store.state.url)
        } 
        if(this.$route.path === '/dashboard/product/warehouses' && this.$store.state.userData.permissions.editingProducts === false) {
          this.$router.push(this.$store.state.url)
        }
        if(this.$route.path === '/dashboard/clients' && this.$store.state.userData.permissions.editingCustomers === false) {
          this.$router.push(this.$store.state.url)
        }
        if(this.$route.path === '/dashboard/customerportal' && this.$store.state.userData.permissions.customerPortal === false) {
          this.$router.push(this.$store.state.url)
        }
        if(this.$route.path === '/dashboard/permissions' && this.$store.state.userData.permissions.editingPermissions === false) {
          this.$router.push(this.$store.state.url)
        }
        if(this.$route.path === '/dashboard/advanced' && this.$store.state.userData.permissions.advancedFeed === false) {
          this.$router.push(this.$store.state.url)
        }
      }

    const loginSubscription = auth.onAuthStateChanged(async (user) => {
      if (user) {
        this.$store.commit("setAuthIsReady", true);
        this.$store.commit("setUser", user);
        if(this.$store.state.userData === null)
        {
          this.$store.commit("setAuthIsReady", false);
          db.collection("UsersData").doc(this.$store.state.user.uid).get().then(async (payload)=>
          {
            this.$store.commit('setUserCredentials', payload.data());
            const systemInfo = await this.$store.state.userData.system.ref.get();
            this.$store.commit('setSystemData', systemInfo.data());
            const result = await axios.post("https://europe-central2-complaia.cloudfunctions.net/api/check/subscription", {
            systemRef: this.$store.state.userData.system.id,
            userId: this.$store.state.user.uid,
          })
            if (result.data.activeSubscription === true) {
              this.$store.commit("setUserSubscription", result.data.subscription);
              if(payload.data().previousPlan !== undefined && result.data.subscription.items[0].price.id !== undefined && payload.data().previousPlan !== result.data.subscription.items[0].price.id && this.$store.state.userData.systemCreator) {
                this.$store.state.globalLoader.head = "You changed your plan"
                this.$store.state.globalLoader.subheader = "We're adjusting system settings"

                let planSettings = {
                  imageSizePerFileMb: eval(result.data.subscription.items[0].price.product.metadata.imageSizePerFileMb),
                  imagesPerComplaint: eval(result.data.subscription.items[0].price.product.metadata.imagesPerComplaint),
                  pdfUpload: eval(result.data.subscription.items[0].price.product.metadata.pdfUpload),
                  userAmount: eval(result.data.subscription.items[0].price.product.metadata.userAmount),
                  planName: result.data.subscription.items[0].price.id
                }
                const checkSystemSettings = await axios.post("https://europe-central2-complaia.cloudfunctions.net/api/check/systemSettings", {
                  systemId: this.$store.state.userData.system.id,
                  userId: this.$store.state.user.uid,
                  currentPlan: planSettings,
                  previousPlan: payload.data().previousPlan,
                })
                this.alertData.bodyText = checkSystemSettings.data.description
                this.showAlert = checkSystemSettings.data.show
              }
              getPermissions();
              this.$store.commit("setAuthIsReady", true);
              relocateTo();
            } else if (!this.$store.state.userData.systemCreator) {
              this.$router.push(`/error?type=SubscriptionExpired&email=${result.data.creatorEmail}`);
            } else if (this.$store.state.userData.systemCreator) {
              this.$router.push(`/selectplanexpired?currentplan=${result.data.currentPlan}`);
            }
          }).catch((error)=>
          {
            console.log(error);
          })
        }
      } else {
        this.$router.push("/");
      }
      // getPermissions()
      
      loginSubscription();

      // This must be here - without this: error on reload caused by async
      if(this.$store.state.userSubscription !== null)
      {
        getPermissions()
      }
    });
  },
  mounted()
  {
    
    // if(!this.isLocalHost(window.location.href))
    // {
    //   Checknet.addEventListener('dropped', () =>{
    //     //what should happen when the connection drops.
    //     this.$store.commit("updateInternetConnectionStatus", false);
    //   });

    //   Checknet.addEventListener('restored', () =>{
    //     //what should happpen when a dropped connection resumes.
    //     this.$store.commit("updateInternetConnectionStatus", true);
    //   });

    //   Checknet.set('checkInterval', 8000);
    //   Checknet.set('checkUrls', ['https://app.complaia.com']);

    //   Checknet.start();
    // }
  },
  watch:{
    '$route'(a)
    {
      if(a.path !== '/dashboard/permissions' && a.path !== '/dashboard/team' && a.path !== '/dashboard/feed' && a.path !== '/dashboard/analytics' && a.path !== '/dashboard/customerportal') {
        this.$store.commit("resetGlobalLoader");
      }
    },
    async '$store.state.authIsReady'(a) {
      if(a) {
        if(this.$store.state.userData.alert !== undefined && this.$store.state.userData.alert.show === true && this.$store.state.userData.alert.description.length > 0 && this.$store.state.userData.systemCreator) {
          this.alertData.bodyText = this.$store.state.userData.alert.description
          this.showAlert = this.$store.state.userData.alert.show
        }
        if(this.$store.state.userData.previousPlan === undefined && this.$store.state.userData.systemCreator) {
          this.$store.state.userData.previousPlan = this.$store.state.userSubscription.items[0].plan.id
          await db.collection('UsersData').doc(this.$store.state.user.uid).update({
            previousPlan: this.$store.state.userSubscription.items[0].plan.id
          })
          await axios.post("https://europe-central2-complaia.cloudfunctions.net/api/sendEmail", {
            attendantEmail: 'no-reply@complaia.com',
            to: 'no-reply@complaia.com',
            messageType: "newUserNotification",
            payload: {
              userName: this.$store.state.userData.name,
              userSurname: this.$store.state.userData.surname,
              systemId: this.$store.state.userData.system.id,
              selectedPlan: this.$store.state.userSubscription.items[0].plan.id,
              companyName: this.$store.state.userData.company,
            }
          })
        }
      }
    },
  },
  methods: {
    async hideAlert() {
      try {
        setTimeout(() => {
          this.showAlert = false
        }, 300)
        db.collection("UsersData").doc(this.$store.state.user.uid).update({
          alert: {
            show: false,
            description: ""
          }
        })
      } catch(error) {
        console.log(error);
      }
    },
    isLocalHost(url) {
      return url.indexOf('localhost') !== -1 || url.indexOf('127.0.0.1') !== -1;
    },
    async getUserData()
    {
      if(this.$store.state.userData === null && this.$store.state !== null)
      {
        await this.$store.dispatch("downloadUserData");
      }
    },
    async logout() {
      try {
        await this.$store.dispatch("logout");
        this.$router.push("/");
      } catch (err) {
        console.log(err.message);
      }
    },
  },
};
</script>
<style>
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s ease;
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }
</style>
